
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { stringSort, shortDate } from "@/utils/helper"
  import { agencyBillKeyDataNew } from "@/utils/billHelper"
  import KeyValue from "@/components/shared/KeyValue.vue"
  import BillKeyData from "@/components/shared/BillKeyData.vue"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import BillEntry from "@/components/admin/BillEntry.vue"
  import { Segment } from "@/models/segments";
  import { segmentSchema, adminUiSegmentSchema } from "@/view_scripts/form_schemas/segment"
  import { admin_agency_bill_segment_columns as columns_provider } from "@/view_scripts/table_columns/admin_segment_table"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { alertDialog } from "@/plugins/alert"
  import moment from "moment"

  export default defineComponent({
    props: {
      bill: {type: Object, required: true},
      segments: {type: Array, default: () => []},
      freeRows: {type: Array, default: () => []},
      pdf: {type: Array, default: () => []},
      meta: {
        type: Object,
        default: function () {
          return {billIncrementor: 'Unbekannt'}
        }
      },
    },
    components: {
      KeyValue,
      BillKeyData,
      SchemaForm,
      FileMetaData,
      BillEntry
    },
    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const billKeyData = computed(() => agencyBillKeyDataNew(props.bill, props.segments, 'crNames'))
      const segmentToEdit = ref<any>(null)
      const editSegmentModal = ref()
      // const billDate = ref<string>(props.bill.attributes?.billDate.split('T')[0])
      const billNr = ref<string>(props.bill.attributes?.billNr)
      const billDate = computed({
        get() {
          const currentBillDate = props.bill.attributes?.billDate
          if (currentBillDate === null) {
            return moment().format('YYYY-MM-DD')
          } else {
            return moment(currentBillDate).format('YYYY-MM-DD')
          }
        },
        set(newValue) {
          props.bill.attributes.billDate = newValue
        }
      })

      const sortedSegments = computed(() => {
        return stringSort(props.segments, (s) => s.meta.crLastname)
      })

      const toExcel = () => {
        const output = sortedSegments.value.map((r) => {
          return [
            // r.meta.crFirstname,
            r.meta.crLastname,
            shortDate(r.attributes.start),
            shortDate(r.attributes.end),
            r.meta.duration,
            r.meta.provision.toString().replace('.',','),
            r.meta.provisionProportional.toString().replace('.',',')
          ].join(';')
        }).join("\n")
        copyToClipboard(output)
        return
      }

      const copyToClipboard = (text:string) => {
        var el = document.createElement('textarea')
        el.value = text
        // Set non-editable to avoid focus and move outside of view
        // el.style = {position: 'absolute', left: '-9999px'}
        el.setAttribute('readonly', '')
        document.body.appendChild(el)
        // Select text inside element
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        prompt('Ins clipboard kopiert!')
      }

      const prompt = (msg:string, type='success') => {
        alertDialog.variant.value = type
        alertDialog.message.value = msg
        alertDialog.show.value = true
      }

      const emitUsecase = (usecase: string, payload: {[key: string]: any} = {}) => {
        const billCopy = JSON.parse(JSON.stringify(props.bill))
        billCopy.attributes.billNr = billNr.value
        billCopy.attributes.billDate = billDate.value
        emit('update', usecase, billCopy, payload)
      }

      const swapSegment = (segment: ResourceObject<string, Segment>) => {
        const segmentIsBilled = ((segment.relationships?.agencyBill as RelationshipsWithData)?.data as ResourceIdentifierObject)?.id
        const agencyBillId = segmentIsBilled ? null : props.bill.id
        const patchData = { id: segment.id, attributes: {agencyBillId} }
        showLoading.value =true
        updateSegment(patchData)
          .then((r) => emitUsecase('update_segments', {segments: [r.resource]}) )
          .finally(() => (showLoading.value = false))
      }

      const saveSegment = async (bvModalEvent: any) => {
        showLoading.value = true
        const segment = segmentToEdit.value
        delete(segment.attributes.agencyBillId)
        updateSegment(segment)
          .then((r) => emitUsecase('update_segments', {segments: [r.resource]}) )
          .finally(() => {
            showLoading.value = false
            root.$nextTick(() => editSegmentModal.value.hide())
          })
      }

      const updateSegment = (segment: any) => {
        if (!segment.id) {alert('updateSegment id missing'); return false}
        if (!segment.attributes) {alert('updateSegment attributes missing'); return false}
        return root.$store.dispatch('segments/edit', { id: segment.id, body: {attributes: segment.attributes}})
      }

      const segmentActions = {
        'move': {
          buttons: [
            {
              action: (data: any) => swapSegment(data.item),
              icon: ['fad', 'random'],
            },
            {
              action: (data: any) => {
                segmentToEdit.value = JSON.parse(JSON.stringify(data.item))
                editSegmentModal.value.show()
              },
              title: 'Abschnitt bearbeiten',
              icon: ['fas', 'pencil-alt'],
            }
          ]
        }
      }

      const updateBillEntries = (msg: string, payload: any) => {
        // console.log('AgencyBill#updateBillEntries', msg, payload)
        if (msg === 'entry_create') {
          props.bill.attributes.entries.push(payload)
        } else if (msg === 'entry_destroy') {
          props.bill.attributes.entries = props.bill.attributes.entries.filter((e) => e.id !== payload.id)
        } else if (msg === 'entry_update') {
          const presentEntry = props.bill.attributes.entries.find((e) => e.id === payload.id)
          presentEntry.attributes = {...presentEntry.attributes, ...payload.attributes}
        }
        emitUsecase('refresh_bill')
      }

      const segmentColumns = columns_provider(segmentActions)
      const freeToNewBill = () => {
        if (props.freeRows.length == 0) return false
        const newBillData = {attributes: {
          month: props.bill.attributes.month,
          year: props.bill.attributes.year,
          agencyId: props.bill.relationships.agency.data.id,
        }}
        showLoading.value = true
        root.$store.dispatch('agency_bills/create', newBillData)
          .then((r) => {
            emit('update', 'new_bill', r.data.newBill)
            return r.data.newBill
          })
          .then((r) => {
            const updateRequests = props.freeRows.map((seg) => {
              const sd = {id: seg.id, attributes: {agencyBillId: r.id}}
              return updateSegment(sd)
            })
            return Promise.all(updateRequests).then((r) => {
              emitUsecase('update_segments', {segments: r.map((e:any)=>e.resource)})
            })
          })
          .finally((r) => {
            showLoading.value = false
          })
      }

      const paidFreeRows = computed( () => props.freeRows.filter( (segment:any) => {
        return segment.attributes.clientPaid
      }))

      const unpaidRows = computed( () => {
        return props.freeRows.filter( (segment:any) => !segment.attributes.clientPaid )
      })

      const segOutput = (segs:any) => {
        return segs.map((s)=>{
          return [s.id, s.meta.provisionProportional].join(':')
        }).join(',')
      }

      return {
        showLoading,
        billKeyData,
        toExcel,
        segmentColumns,
        segmentSchema,
        adminUiSegmentSchema,
        saveSegment,
        editSegmentModal,
        segmentToEdit,
        sortedSegments,
        emitUsecase,
        freeToNewBill,
        billDate,
        billNr,
        updateBillEntries,
        moment,
        paidFreeRows,
        unpaidRows,
        segOutput
      }
    }
  })
