export const admin_agency_bill_segment_columns = (buttons: any) => {
  const columns = [
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   key: "id",
    //   label: "ID",
    // },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      key: "meta.crLastname",
      title: "Vertrag (PB-Name)",
      label: "Vertrag",
      sortable: true,
      options: {
        target: '_blank',
        hrefFunction: (data:any) => {
          return `/contracts/${data.item.meta.contract?.id||data.item.meta.contractId}`
        },
        contentFunction: (data:any) => {
          return data.item.meta.crNames || 'Kein Name'
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.start",
      title: "Start",
      label: "Start",
      sortable: true,
      options: {
        type: 'date',
        cellClassFunction: (data: any) => {
          const atts = data.item.attributes
          if (atts.start !== atts.agStart) {
            return 'text-warning'
          } else { return '' }
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.agStart",
      title: "Start (Ag)",
      label: "Start (Ag)",
      sortable: true,
      options: {
        type: 'date',
        cellClassFunction: (data: any) => {
          const atts = data.item.attributes
          if (atts.start !== atts.agStart) {
            return 'text-warning'
          } else { return '' }
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.end",
      title: "Ende",
      label: "Ende",
      sortable: true,
      options: {
        type: 'date',
        cellClassFunction: (data: any) => {
          const atts = data.item.attributes
          if (atts.end !== atts.agEnd) {
            return 'text-warning'
          } else { return '' }
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.agEnd",
      title: "Ende (Ag)",
      label: "Ende (Ag)",
      sortable: true,
      options: {
        type: 'date',
        cellClassFunction: (data: any) => {
          const atts = data.item.attributes
          if (atts.end !== atts.agEnd) {
            return 'text-warning'
          } else { return '' }
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "meta.provPfs",
      title: "Prov.",
      label: "Prov.",
      sortable: true,
      options: {
        contentFunction: (data:any) => {
          const atts = data.item.attributes
          return atts ? atts.provPfs + atts.provSeller : 'NA'
        },
        cellClassFunction: (data: any) => {
          const atts = data.item.attributes
          if (atts && atts.provAgencyTotal !== atts.provSeller+atts.provPfs) {
            return 'text-warning'
          } else { return '' }
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.provAgencyTotal",
      title: "Prov. (Ag)",
      label: "Prov. (Ag)",
      options: {
        cellClassFunction: (data: any) => {
          const atts = data.item.attributes
          if (atts && atts.provAgencyTotal !== atts.provSeller+atts.provPfs) {
            return 'text-warning'
          } else { return '' }
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.discountCg",
      title: "Monatsrabatt",
      label: "Monatsrabatt",
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "meta.duration",
      title: "Tage",
      label: "Tage",
      sortable: true,
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "meta.provisionProportional",
      title: "Prov. prop.",
      label: "Prov. prop.",
      sortable: true,
      // options: {
      //   cellClassFunction: (data: any) => {
      //     return data.item.attributes.clientPaid ? '' : 'text-danger'
      //   }
      // }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.agencyBillPaid",
      title: "Zhlg. rein",
      label: "Zhlg. rein",
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.sellerBillPaid",
      title: "Zhlg. raus",
      label: "Zhlg. raus",
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      key: "attributes.note",
      title: "Notiz",
      label: "Notiz",
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "move",
      label: "",
      options: {}
    },
  ]
  columns.forEach((e,i) => {e.options = {...e.options, ...buttons[e.key]}})
  return columns
}