
  import { defineComponent, reactive, ref, computed, getCurrentInstance } from "vue"
  import { confirm, prompt } from '@/utils/interactionModals'

  export default defineComponent({
    props: {
      bill: {
        type: Object,
        required: true
      },
      entry: { type: Object },
      edit: {
        type: Boolean,
        default: false
      }
    },

    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const typeOptions = [
        // { value: null, text: 'Please select an option' },
        { value: 'charge', text: 'Leistung' },
        { value: 'credit', text: 'Gutschrift' },
        { value: 'avi', text: 'Avi' },
      ]
      const netValue = computed(() => {
        const vpu = props.entry?.attributes?.value_per_unit || 0
        const count = props.entry?.attributes?.count || 1
        return (Math.round(vpu*100) * count)/100
      })
      const grossValue = computed(() => {
        const netInt = Math.round(netValue.value*100)
        const taxInt = Math.round((1+props.bill.attributes.taxRate)*100)
        return (netInt * taxInt)/10000
      })
      const submit = (usecase: string) => {
        const data = {id: props.bill.id, billEntry: ent.value}
        showLoading.value = true
        root.$store.dispatch(`agency_bills/${usecase}`, data).then((r) => {
          console.log('BillEntry#submit', usecase, r)
          emit('entry-changed', usecase, r.data.resource)
          if (usecase === 'entry_create') ent.value = newBillEntry()
        }).finally(() => showLoading.value = false)
      }

      const destroy = async () => {
        const msg = `Soll der Eintrag: >${ent.value.attributes.label}< gelöscht werden?`
        const confirmation = await confirm(root, msg)
        if (confirmation) {
          const data = {id: props.bill.id, billEntry: ent.value}
          const usecase = 'entry_destroy'
          root.$store.dispatch(`agency_bills/${usecase}`, data)
            .then((r) => {
              emit('entry-changed', usecase, r.data)
            })
            .finally(() => showLoading.value = false)
        }
      }

      const newBillEntry = () => {
        return {
          id: 'new',
          type: 'bill_entries',
          attributes: {
            type: 'charge',
            billNr: props.bill.attributes?.billNr,
            billDate: props.bill.attributes?.billDate,
            label: '',
            count: 1,
            valuePerUnit: 0,
          }
        }
      }
      const ent = ref(props.entry || newBillEntry())
      const billDate = computed({
        get() { return ent.value.attributes.billDate?.split('T')[0] },
        set(newValue) { ent.value.attributes.billDate = newValue }
      })
      const isNew = computed(() => ent.value.id === 'new')

      return {
        showLoading,
        isNew,
        billDate,
        submit,
        destroy,
        typeOptions,
        netValue,
        grossValue,
        ent
      }
    }
  })
