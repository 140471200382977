
  import { computed, defineComponent, ref, reactive, watch, getCurrentInstance } from "vue"
  import { extendAgencyBillSegment, totals, billVariantIndex, ranks, segmentsProvisionSum, summizeList, billManEntriesSum, lastMonth } from "@/utils/billHelper"
  import { generateYearSelectOptions, generateMonthSelectOptions, round, stringSort } from "@/utils/helper"
  import { fullName } from "@/utils/dataExtractors"
  import { ResourceObject } from '@/models/jsonapi'
  import { Segment } from "@/models/segments"
  import { AgencyBill as AgencyBillModel } from "@/models/agency_bills"
  import { confirm, prompt } from '@/utils/interactionModals'
  import AgencyBill from "@/components/admin/AgencyBill.vue"
  import { segmentSchema, adminUiSegmentSchema } from "@/view_scripts/form_schemas/segment"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { admin_agency_bill_segment_columns as columns_provider } from "@/view_scripts/table_columns/admin_segment_table"

  export default defineComponent({
    components: {
      AgencyBill,
      SchemaForm
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const editSegmentModal = ref()
      const segmentToEdit = ref<any>(null)
      const yearOptions = generateYearSelectOptions()
      const monthOptions = generateMonthSelectOptions()
      const metaInfo = ref<any>({billIncrementor: 'NA'})
      const showLoading = ref(false)
      const yearParam = root.$route.query?.year as string
      const monthParam = root.$route.query?.month as string
      const year = ref<number>(yearParam ? parseInt(yearParam) : lastMonth(new Date()).getFullYear())
      const month = ref<number>(monthParam ? parseInt(monthParam) : lastMonth(new Date()).getMonth() +1)
      const selectedBill = ref<any>({})
      const selectedAgency = ref<string>('')
      const sourceData = ref({})
      const index = ref({})
      const segmentIndex = ref({})
      const accordionTitleIndex = ref({})
      const unpaidSegments = ref([])
      const sample = ref<any>([])
      const unpaidSegmentsTable = ref<any>(null)
      const agencyUnpaidSegmentsTable = ref<any>(null)
      const monthTotal = reactive({total: 0, billed: 0, unBilled: 0, paid: 0, unPaid: 0})
      const segmentCountRequest = () => {
        const yearSwap = month.value + 1 === 13
        const toMonth = yearSwap ? '1' : month.value + 1
        const toYear = yearSwap ? year.value + 1 : year.value
        return {
          'filter[start.gte]': `${year.value}-${month.value}-1`,
          'filter[start.lt]': `${toYear}-${toMonth}-1`,
          'filter[contract_id.ne]': 'null',
          'fields[segments]':[],
        }
      }

      // these parameters are directed to the segments index endpoint, even though they are sent to non restfull agency_bills/bill_data
      const segmentRequestParameters = {
        'include': 'contract.agency,contract.household.care_receivers.address,contract.household.lead.address,agency_bill.agency,agency_bill.file_meta_data',
        // 'include': 'segments.contract.household.care_receivers.address,segments.contract.household.lead.address,agency',
        'fields[segments]': 'all',
        'fields[agencies]': 'name',
        'fields[agency_bills]': 'bill_nr,presented,reviewed,paid,entries,billDate,month,year',
        'fields[addresses]': 'first_name,last_name',
        'fields[file_meta_datas]': 'filename,length,updated_at,mail_included,contentType',
      }
      const resetView = () => {
        index.value = {}
        segmentIndex.value = {}
        sourceData.value = {}
        selectedBill.value = {}
        selectedAgency.value = ''
      }

      const requestDataNew = async () => {
        resetView()
        showLoading.value = true
        const params = { ...segmentCountRequest(), ...segmentRequestParameters }
        root.$store.dispatch('agency_bills/bill_data', segmentCountRequest())
          .then((r) => {
            params['page[size]'] = r.meta.totalItems
            root.$store.dispatch('agency_bills/bill_data', params)
              .then(processServerData)
              .then(extendSegments)
              .then(createAccordeonTitles)
              .then(calculateMonthTotal)
              .finally(()=>{
                showLoading.value = false
              })
          })
      }

      const processServerData = (response: any) => {
        const start = Date.now()
        sourceData.value = response
        const tmpSegments = response.data.reduce((res:any, segment:any) => {
          res[segment.id] = extendAgencyBillSegment(segment)
          return res
        }, {})
        const tmpIndex = response.included.reduce((res:any, entry:any) => {
          res[entry.id] = entry
          return res
        }, {})
        index.value = {...index.value, ...tmpIndex}
        segmentIndex.value = {...segmentIndex.value, ...tmpSegments}
        updateBillIncrementor(response.meta.lastBillNrIncrementor)
        // console.log('siv',segmentIndex.value)
        console.log('processServerData: ', (Date.now()-start))
      }

      const extendSegments = () => {
        const start = Date.now()
        Object.values(segmentIndex.value).forEach(extendSegment)
        console.log('extendSegments: ', (Date.now()-start))
      }

      const extendSegment = (segment: any) => {
        const leadAddId = traverseFetchId(segment.id, 'contract.household.lead.address')
        const leadName = fullName(index.value[leadAddId])
        const crAdds = traverseFetchId(segment.id, 'contract.household.careReceivers.address')
          .map((i:string) => index.value[i])
        const ext = {
          leadName: leadName,
          crNames: crAdds.map((a:any) => (!a ? `EB Addresse fehlt: ${segment.id}` : fullName(a)) ).join(', '),
          crLastname: crAdds.map((a:any) => (!a ? `EB Addresse fehlt: ${segment.id}` : a.attributes.lastName) ).join(', '),
          contractId: segment.relationships.contract.data.id
        }
        segment.meta = {...segment.meta, ...ext}
        return segment
      }

      const createAccordeonTitles = () => {
        const start = Date.now()
        const res = agencies.value.reduce((res: any, ag:any) => {
          res[ag.id] = accordionTitle(ag.id)
          return res
        }, {})
        // console.log('createAccordeonTitles res: ', res['602bbcc22c34845a0d27e125'])
        accordionTitleIndex.value = res
        console.log('createAccordeonTitles: ', (Date.now()-start))
      }


      const testReaction = () => {
        createAccordeonTitles()
      }

      const traverseFetchId = (startId: string, traversal: string) => {
        const dotIndex = traversal.indexOf('.')
        const nextType = dotIndex === -1 ? traversal : traversal.slice(0, dotIndex)
        const entity = index.value[startId] || segmentIndex.value[startId]
        const data = entity?.relationships[nextType]?.data
        if (Array.isArray(data)) {
          return data.map((ro:any) => {return dotIndex === -1 ? ro.id : traverseFetchId(ro.id, traversal.slice(dotIndex+1))})
        } else if (typeof(data) === 'object') {
          return dotIndex === -1 ? data.id : traverseFetchId(data.id, traversal.slice(dotIndex+1))
        } else {
          console.log('unexpected data in traverseFetchId: ', index.value[startId], nextType)
          // alert('traverseFetchId error')
        }
      }

      const agencies = computed(() => {
        const t = Date.now()
        const age = Object.values(index.value).filter((e:any) => e.type == 'agencies')
        const res = stringSort(age, (agency) => agency.attributes.name )
        console.log('computing agencies: ',(Date.now()-t))
        return res
      })

      const selectedBillSegments = computed(() => {
        const start = Date.now()
        return segmentsForBillId(selectedBill.value.id)
        // console.log('selectedBillSegments: ', (Date.now()-start))
      })

      const billsForAgencyId = (agencyId: string) => {
        const start = Date.now()
        // const agency = index.value[agencyId]
        const bills = Object.values(index.value).filter((e:any) => {
          const isBill = e.type === 'agency_bills'
          return isBill && e.relationships?.agency?.data?.id === agencyId
        })
        const res = stringSort(bills, (b:any) => b.attributes.billNr)
        // console.log({res})
        // console.log('billsForAgencyId: ', (Date.now()-start))
        return res
      }

      const accordionVariant = (agencyId: string) => {
        const start = Date.now()
        let res = 4
        billsForAgencyId(agencyId).forEach((bill) => {
          const idx = billVariantIndex(bill)
          res = idx < res ? idx : res
        })
        // console.log('accordionVariant: ', (Date.now()-start))
        return ranks[res]
      }

      const segmentsForBillId = ((billId: string) => {
        const start = Date.now()
        const res = Object.values(segmentIndex.value).filter((e: any) => {
          return e.relationships?.agencyBill?.data?.id === billId
        })
        console.log('segmentsForBillId: ', (Date.now()-start))
        return res
      })

      const toggleSelectedAgency = (agencyId: string) => {
        const selected = selectedAgency.value === agencyId
        if (selected) {
          selectedAgency.value = ''
        } else {
          selectedAgency.value = agencyId
        }
        selectedBill.value = {}
      }

      const billButtonVariant = (bill: any) => ranks[billVariantIndex(bill)]

      const toggleSelectedBill = (data: any) => {
        const cond = selectedBill.value?.id === data.id
        selectedBill.value = cond ? {} : data
      }

      const accordionTitle = (agencyId: string) => {
        const mES = manEntriesSumsForBills(billsForAgencyId(agencyId))
        const billedSegments:any[] = []
        const unBilledSegments:any[] = []
        const paidSegments:any[] = []
        const unPaidSegments:any[] = []
        const agSegments = Object.values(segmentIndex.value).filter((entry:any) => {
          const shouldFilter = traverseFetchId(entry.id, 'contract.agency') === agencyId
          if (shouldFilter) {
            const billId = entry.relationships?.agencyBill?.data?.id
            if (billId) {
              billedSegments.push(entry)
              index.value[billId].attributes.paid ? paidSegments.push(entry) : unPaidSegments.push(entry)
            } else {
              unBilledSegments.push(entry)
              unPaidSegments.push(entry)
            }
          }
          return shouldFilter
        })

        const agName = index.value[agencyId].attributes.name
        // console.log('agencyManEntriesSum agency: ', agName, agencyManEntriesSum)

        const result = [
          `${agName}: ${round(discountedSegProvSum(agSegments) + mES.all)}`,
          `Rechnungen: ${round(discountedSegProvSum(billedSegments) + mES.all)}`,
          `Frei: ${round(discountedSegProvSum(unBilledSegments))}`,
          `Bezahlt: ${round(discountedSegProvSum(paidSegments) + mES.paid)}`,
          `Offen: ${round(discountedSegProvSum(unPaidSegments) + mES.unPaid)}`,
        ]
        return result
      }

      const discountedSegProvSum = ((segs: any[]) => {
        return summizeList(segs, (s=>{
          return s.meta.provisionProportional - s.attributes.discountSeller
        }))
      })

      const calculateMonthTotal = () => {
        const t = Date.now()
        // const result = {total: 0, billed: 0, unBilled: 0, paid: 0, unPaid: 0}
        Object.keys(monthTotal).forEach((a:any) => monthTotal[a] = 0 )
        const bills = Object.values(index.value).filter((e:any) => e.type === 'agency_bills')
        const mES = manEntriesSumsForBills(bills)

        Object.values(segmentIndex.value).forEach((s:any) => {
          const prov = s.meta.provisionProportional - s.attributes.discountSeller
          const billId = s.relationships?.agencyBill?.data?.id
          const billed = billId != undefined
          const paid = billed && index.value[billId].attributes.paid

          monthTotal.total += prov
          monthTotal[(billed ? 'billed' : 'unBilled')] += prov
          monthTotal[(paid ? 'paid' : 'unPaid')] += prov
        })

        monthTotal.total = round(monthTotal.total + mES.all)
        monthTotal.billed = round(monthTotal.billed + mES.all)
        monthTotal.unBilled = round(monthTotal.unBilled + mES.all)
        monthTotal.paid = round(monthTotal.paid + mES.paid)
        monthTotal.unPaid = round(monthTotal.unPaid + mES.unPaid)
        console.log('calculating monthTotal: ',Date.now()-t)
        return monthTotal
      }

      const manEntriesSumsForBills = (bills: any[]) => {
        const paid:any[] = []
        const unPaid:any[] = []
        bills.forEach((b) => b.attributes.paid ? paid.push(b) : unPaid.push(b) )
        return {
          paid: summizeList(paid, billManEntriesSum),
          unPaid: summizeList(unPaid, billManEntriesSum),
          all: summizeList(bills, billManEntriesSum),
        }
      }

      const unbilledAgencySegments = (agencyId: string) => {
        return Object.values(segmentIndex.value).filter((s:any) => {
          const segAgencyId = traverseFetchId(s.id, 'contract.agency')
          const isUnbilled = s.relationships?.agencyBill?.data?.id === undefined
          // const isPaid = s.attributes.clientPaid
          return segAgencyId === agencyId && isUnbilled
        })
      }

      const selectedBillPdf = computed(() => {
        const sb = index.value[selectedBill.value.id]
        if (sb) {
          // console.log({sb})
          const fmdId = sb.relationships?.fileMetaData?.data?.id
          const fmd = index.value[fmdId]
          return fmd ? [fmd] : []
        } else {
          // console.log('no selectedBill')
          return []
        }
      })

      const widthForIndex = (idx: number) => {
        if (idx < 2) {
          return 'w-w'
        } else {
          return 'w-s'
        }
      }

      const updateBillIncrementor = (val: number) => {
        metaInfo.value.billIncrementor = val
      }

      const updateBill = (bill: any) => {
        showLoading.value = true
        // console.log('AgencyBills.vue updateBill data: ', data)
        return root.$store.dispatch('agency_bills/edit', {id: bill.id, body: bill})
          .then((r) => {
            console.log('updateBill response', r)
            if (r.data.message.lastBillNrIncrementor) {
              console.log('updating lastBillNrIncrementor')
              updateBillIncrementor(r.data.message.lastBillNrIncrementor)
            }
            if (r.data.resource) { registerBill(r.data.resource) }
            if (r.data.pdf) {
              index.value[r.data.pdf.id] = r.data.pdf
              selectedBill.value.relationships.fileMetaData = { data: {
                id: r.data.pdf.id,
                type: 'file_meta_datas'
              }}
            }
          })
          .finally(()=>{
            calculateMonthTotal()
            createAccordeonTitles()
            showLoading.value = false
          })
      }

      const registerBill = (bill: any) => {
        const presentBill = index.value[bill.id]
        if (presentBill) {
          presentBill.attributes = { ...presentBill.attributes, ...bill.attributes}
        } else { index.value[bill.id] = bill }
        // } else { index.value = {...index.value, ...{bill.id: bill} } }
      }

      const refreshView = () => {
        showLoading.value = true
        calculateMonthTotal()
        createAccordeonTitles()
        showLoading.value = false
      }

      const confirmMessage = (bill: any) => {
        if (bill.attributes.paid) {
          prompt(root, `Rechnung: ${bill.attributes.billNr} ist schon als bezahlt markiert. Bitte zuerst den Status "Bezahlt" aufheben.`)
          return 'paid'
        }
        if (bill.attributes.reviewed && bill.attributes.presented) {
          return `Rechnung: ${bill.attributes.billNr} ist versiegelt. Dennoch Fortfahren?`
        } else if (bill.attributes.reviewed) {
          return `Rechnung: ${bill.attributes.billNr} ist als geprüft markiert. Dennoch Fortfahren?`
        } else {
          return 'default'
        }
      }

      const setNew = async (bill: any) => {
        const confMsg = confirmMessage(bill)
        if (confMsg === 'paid') return false
        let msg = `Rechnung: ${bill.attributes.billNr} wird auf "Neu" gestellt und kann wieder bearbeitet werden!`
        if (bill.attributes.presented && !bill.attributes.reviewed) {
          msg += ` Falls die Agentur die Rechnung gerade bearbeitet, verliert sie dadurch Schreibrecht und ein Fehler wird angezeigt. Fortfahren?`
        } else if (confMsg != 'default') { msg += ` ${confMsg}` }
        const confirmation = await confirm(root, msg)
        const patchData = {id: bill.id, usecase: 'set_new'}
        if (confirmation) updateBill(patchData)
      }

      const setCheck = async (bill: any) => {
        const confMsg = confirmMessage(bill)
        if (confMsg === 'paid') return false
        let msg = `Rechnung: ${bill.attributes.billNr} wird auf "Prüfung" gestellt und der Agentur auf dem Dashboard zur Prüfung vorgelegt.`
        if (confMsg != 'default') { msg += ` ${confMsg}` }
        const confirmation = await confirm(root, msg)
        const patchData = {id: bill.id, usecase: 'set_check'}
        if (confirmation) updateBill(patchData)
      }

      const setLocked = async (bill: any) => {
        let msg = `Rechnung: ${bill.attributes.billNr} wird "Versiegelt" und ist damit schreibgeschützt.`
        if (bill.attributes.reviewed && bill.attributes.presented) {
          prompt(root, `Rechnung: ${bill.attributes.billNr} ist schon Versiegelt.`)
          return 'false'
        } else if (bill.attributes.paid === true) {
          msg += ` Die Rechnung ist als "Bezahlt" markiert. Es wird kein PDF erzeugt.`
        } else if (bill.attributes.paid === false) {
          msg += ` Die Rechnung ist nicht als "Bezahlt" markiert, ein PDF wird erstellt und der Rechnungszustand gespeichert.`
        }
        const confirmation = await confirm(root, msg)
        const patchData = {id: bill.id, usecase: 'set_locked'}
        if (confirmation) updateBill(patchData)
      }

      const togglePaid = async (bill:any) => {
        let msg
        let toggleAttributes
        if (bill.attributes.paid) {
          msg = `WARNUNG! Rechnung: ${bill.attributes.billNr} ist als bezahlt markiert. Soll sie auf "Unbezahlt" gestellt werden?`
          toggleAttributes = {id: bill.id, usecase: 'set_unpaid'}
        } else {
          msg = `Rechnung: ${bill.attributes.billNr} wird als gezahlt markiert?`
          toggleAttributes = {id: bill.id, usecase: 'set_paid'}
        }
        const confirmation = await confirm(root, msg)
        if (confirmation) updateBill(toggleAttributes)
      }

      const renderPdf = async (bill: any) => {
        if (bill.attributes.paid) {
          prompt(root, `Rechnung: ${bill.attributes.billNr} ist schon als bezahlt markiert. Bitte zuerst den Status "Bezahlt" aufheben.`)
          return false
        }
        const confirmation = await confirm(root, 'Ein neues PDF wird erstellt und der der Datenzustand gespeichert/überschrieben. Fortfahren?')
        if (confirmation) updateBill({id: bill.id, usecase: 'render_pdf'})
      }

      const messageReceived = (usecase: string, bill: {[key: string]: any}, payload:any = null) => {
        // console.log('messageReceived', usecase, bill, payload)
        if (usecase === 'update_segments') {
          console.log('updateSegments: ', payload)
          payload.segments.forEach((segment:any) => {
            const currSeg = segmentIndex.value[segment.id]
            delete(segment.attributes.agencyBillId)
            segmentIndex.value[segment.id] = extendAgencyBillSegment({...currSeg, ...segment})
            getUnpaidSegments()
          })
          refreshView()
        } else if (usecase === 'refresh_bill') {
          registerBill(bill)
          refreshView()
        } else if (usecase === 'render_pdf') { renderPdf(bill) }
        else if (usecase === 'set_new') { setNew(bill) }
        else if (usecase === 'set_check') { setCheck(bill) }
        else if (usecase === 'set_locked') { setLocked(bill) }
        else if (usecase === 'toggle_paid') { togglePaid(bill) }
        else if (usecase === 'update') { updateBill(bill) }
        else if (usecase === 'new_bill') { registerBill(bill) }
        else { alert('Unbekannter Usecase empfangen: '+usecase) }
      }

      const processSegmentsNotPaid = (response: any) => {
        const tmpSegments = response.data.reduce((res:any, segment:any) => {
          res[segment.id] = extendAgencyBillSegment(segment)
          return res
        }, {})
        const tmpIndex = response.included.reduce((res:any, entry:any) => {
          res[entry.id] = entry
          return res
        }, {})
        index.value = {...index.value, ...tmpIndex}
        segmentIndex.value = {...segmentIndex.value, ...tmpSegments}
      }
      const getUnpaidSegmentsCount = async () => {
        const params = {
          'filter[client_paid]': false,
          'filter[contract_id.ne]': 'null',
        }
        return root.$store.dispatch('segments/load2', params).then((response:any) => {
          console.log("count", response)
          return response.meta.totalItems
        })
      }

      const getUnpaidSegments = async () => {
        console.log('getUnpaidSegments')
        const count = await getUnpaidSegmentsCount()
        const segmentsQuery = {
          'include': 'contract.agency,contract.household.care_receivers.address,contract.household.lead.address,care_giver_instance.care_giver',
          // 'include': 'segments.contract.household.care_receivers.address,segments.contract.household.lead.address,agency',
          'fields[segments]': 'all',
          'fields[agencies]': 'name',
          'fields[addresses]': 'first_name,last_name',
          'fields[care_givers]': 'first_name,last_name',
          'filter[client_paid]': false,
          'filter[contract_id.ne]': 'null',
          'page[number]': 1,
          'page[size]': count,
        }
        showLoading.value = true
        root.$store.dispatch('segments/load2', segmentsQuery).then((response:any) => {
          const tmpIndex = response.included.reduce((res:any, entry:any) => {
            res[entry.id] = entry
            return res
          }, {})
          unpaidSegments.value = response.data.map((seg:any) => {
            const con = tmpIndex[seg.relationships.contract.data.id]
            const hh = tmpIndex[con.relationships.household.data.id]
            const lead = tmpIndex[hh.relationships.lead.data.id]
            const leadAdd = tmpIndex[lead.relationships.address.data.id]
            const crs = hh.relationships.careReceivers.data.map((cr) => { return tmpIndex[cr.id] })
            const crAdds = crs.map((cr) => tmpIndex[cr.relationships.address.data.id])
            const ag = tmpIndex[con.relationships.agency.data.id]
            const cgi = tmpIndex[seg.relationships.careGiverInstance.data.id]
            const cg = tmpIndex[cgi.relationships.careGiver.data.id]
            // console.log('con: ',con, 'hh: ',hh, 'lead: ',lead, 'leadAdd: ',leadAdd,'crs: ',crs,'crAdds: ',crAdds,'ag: ',ag,'cgi: ',cgi, 'cg: ',cg)
            const tableItem = {...seg, meta:
              {
                contract: con,
                agency: ag,
                cgName: fullName(cg),
                leadName: fullName(leadAdd),
                crNames: crAdds.map((e) => fullName(e)).join(', '),
              }
            }
            extendAgencyBillSegment(tableItem)
            return tableItem
          })
          unpaidSegmentsTable.value.refresh()
          showLoading.value = false
        })
      }
      getUnpaidSegments()

      const unpaidAgencySegments = (agencyId:string) => {
        return unpaidSegments.value.filter( (row:any) => {
          return row?.meta?.agency?.id === agencyId
        } )
      }

      const segmentActions = {
        'move': {
          buttons: [
            {
              action: (data: any) => {
                segmentToEdit.value = JSON.parse(JSON.stringify(data.item))
                editSegmentModal.value.show()
              },
              title: 'Abschnitt bearbeiten',
              icon: ['fas', 'pencil-alt'],
            }
          ]
        }
      }

      const columnsNotPaid = columns_provider(segmentActions)

      const saveSegment = async (bvModalEvent: any) => {
        showLoading.value = true
        const segment = segmentToEdit.value
        delete(segment.attributes.agencyBillId)
        updateSegment(segment)
          // .then((r) => emitUsecase('update_segments', {segments: [r.resource]}) )
          .then((r) => {messageReceived('update_segments', {}, {segments: [r.resource]})})
          .finally(() => {
            showLoading.value = false
            segmentToEdit.value = null
            root.$nextTick(() => editSegmentModal.value.hide())
          })
      }

      const updateSegment = (segment: any) => {
        // if (!segment.id) {alert('updateSegment id missing'); return false}
        // if (!segment.attributes) {alert('updateSegment attributes missing'); return false}
        return root.$store.dispatch('segments/edit', { id: segment.id, body: {attributes: segment.attributes}})
      }

      return {
        showLoading,
        year,
        yearOptions,
        month,
        monthOptions,
        // requestData,
        // billIndex,
        // cid2Info,
        // agData,
        widthForIndex,
        selectedBill,
        selectedAgency,
        toggleSelectedBill,
        toggleSelectedAgency,
        messageReceived,
        // monthTot,
        round,
        billButtonVariant,
        accordionVariant,
        metaInfo,
        columnsNotPaid,
        requestDataNew,
        sourceData,
        index,
        agencies,
        billsForAgencyId,
        testReaction,
        sample,
        traverseFetchId,
        monthTotal,
        selectedBillPdf,
        unbilledAgencySegments,
        selectedBillSegments,
        segmentIndex,
        accordionTitleIndex,
        registerBill,
        unpaidSegmentsTable,
        agencyUnpaidSegmentsTable,
        saveSegment,
        segmentSchema,
        adminUiSegmentSchema,
        segmentToEdit,
        editSegmentModal,
        unpaidSegments,
        unpaidAgencySegments
      }
    }
  })
